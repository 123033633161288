import { Facebook, LinkedIn, Youtube } from '@vhl/icons/social'

type SocialType = 'Facebook' | 'LinkedIn' | 'Youtube'

interface SocialIconProps {
  type: SocialType
  props?: React.SVGProps<SVGSVGElement>
}

const SocialIcon = ({ type, props }: SocialIconProps) => {
  switch (type) {
    case 'Facebook':
      return (
        <Facebook
          {...props}
          className="transition duration-150 rounded-full fill-black hover:fill-blue-500"
        />
      )
    case 'LinkedIn':
      return (
        <LinkedIn
          {...props}
          className="transition duration-150 fill-black hover:fill-blue-500"
        />
      )
    default:
      return (
        <Youtube
          {...props}
          className="transition duration-150 fill-black hover:fill-blue-500"
        />
      )
  }
}
const Social = ({ type, href }: { type: SocialType; href: string }) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      aria-label={`Visit our ${type} page`}
    >
      <SocialIcon type={type} props={{ width: 32, height: 32 }} />
      <span className="sr-only">Visit our {type} page</span>
    </a>
  )
}

export default Social
